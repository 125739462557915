$(document).ready(initPage);

function initPage(){

  $('.testimonials-videos .btn-more').click(function() {
    $('.testimonials-videos .videos-more').removeClass('collapsed');
    $(this).parent().addClass('collapsed');
  });
  $('.testimonials-texts .btn-more').click(function() {
    $('.testimonials-texts .texts-more').removeClass('collapsed');
    $(this).parent().addClass('collapsed');
  });

  $('.header-menu-btn').click(function() {
    $(this).toggleClass('expanded');
    $('.header-menu-list').toggleClass('expanded');
  });

  $('.header-menu-list a').click(function(){
    event.preventDefault();
    $('.header-menu-btn').toggleClass('expanded');
    $('.header-menu-list').toggleClass('expanded');
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
  });

  
  $('.testimonials-videos').magnificPopup({
    delegate: '.item',
    type: 'iframe',
    fixedContentPos: false,
    removalDelay: 500,
    mainClass: 'mfp-fade',
    gallery: {
      enabled: true
    },
    iframe: {
      markup: '<div class="mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
        '</div>',
      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: 'v=',
          src: 'https://www.youtube.com/embed/%id%?autoplay=1&rel=0'
        },

      }
    },
    callbacks: {
      beforeOpen: function() {
        var $triggerEl = $(this.st.el),
        newClass = $triggerEl.data("modal-class");
        if (newClass) {
          this.st.mainClass = this.st.mainClass + ' ' + newClass;
        }
      }
    }
  });



  $(window).scroll(function(){
    var st = $(this).scrollTop();
    if (st > 0) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }
  });


}